export const COMPANY_CONSTANTS = {
  COMPANY_NAME: "Chef's Farm",
  STREET_AND_NUMBER: "Qiblah, Duwaliya Complex, Floor 4, Office 10",
  ZIP_AND_CITY: "Block 12, Kuwait City",
  COUNTRY: "Kuwait",
  KVK: "",
  VAT: "",
  WEBSITE_URL: "shop.chefsfarm.nl",
  WEBSITE_NAME: "Chef's Farm KW",
};
export const LOGO_SRC = "/img/logo.png";
export const COMPANY_COUNTRY_CODE = "KW";

export const REGIONS = ["NL", "KW", "SG"];
